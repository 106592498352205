import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import user from '../../assets/images/user-icon.png';
// import pin from '../../assets/images/pin-icon.png';
import cart from '../../assets/images/cart.png';
import API from '../../api';

@inject('store')
@observer
class TopIconLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.cartInformation();
  }

  logout = () => {
    this.props.store.makeLogout();
    window.location.href = '/';
  };

  cartInformation = () => {
    const that = this;
    if (this.props.store.auth) {
      API.get('/catalog/cart/')
        .then((response) => {
          that.props.store.getCartCount(response.data.count);
        })
        .catch((error) => {
        // console.log(error);
          if (error.response.status === 400) {
            toast.error(error.response.data.error);
          }
        });
    } else {
      // const data = { cart_id: localStorage.getItem('cartId') };
      API.get(`/catalog/tempcart/${localStorage.getItem('cartId')}/`)
        .then((response) => {
          that.props.store.getCartCount(response.data.count);
        })
        .catch((error) => {
        // console.log(error);
          if (error.response.status === 400) {
            toast.error(error.response.data.error);
          }
        });
    }
  };

  render() {
    return (
      <div className="top-icons-group">
        {this.props.store.auth && (
          <>
            <a href="javascript:void(0);" className="auth-links" onClick={() => this.logout()}>
              Logout
            </a>
            <Link to="/profile">
              <img alt="user" src={user} />
            </Link>
          </>
        )}
        {this.props.store.auth === false && (
          <>
            <Link to="/login" className="auth-links">
              Login
            </Link>
            <Link to="/sign-up" className="auth-links">
              Register
            </Link>
          </>
        )}
        {/* <a href="javascript:void(0);">
          <img alt="location" src={pin} />
        </a> */}
        <Link to="/cart" className="cart-link">
          {this.props.store.cartCount > 0 && <span>{this.props.store.cartCount}</span>}
          <img alt="cart" src={cart} />
        </Link>
      </div>
    );
  }
}

export default TopIconLinks;
