import React from 'react';
// import logo from "./logo.svg"
import { Provider } from 'mobx-react';
import Base from './layout/Base';

import loading from './assets/images/loading.gif';
import disconnect from './assets/images/disconnect.png';

import AppStore from './store';

// const store = window.store = new AppStore();
const store = new AppStore();

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <img src={loading} alt="loading" className="preload-image" />
        <img src={disconnect} alt="disconnect" className="preload-image" />
        <Base />
      </div>
    </Provider>
  );
}

export default App;
