/* eslint-disable max-len */
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import { Dropdown } from 'react-bootstrap';

import PartSearch from '../components/part-search/PartSearch';

import closeBlack from '../assets/images/close-black.png';
import trash from '../assets/images/trash.png';
import car from '../assets/images/car.png';
import carParts from '../assets/images/part.png';

import fillStar from '../assets/images/fill-star.png';
import lineStar from '../assets/images/line-star.png';

import API from '../api';

const subMenu = (s) => (
  <ul className="dropdown">
    {s.map((m) => (
      <li>
        <a href={m.link} target={m.target}>
          {m.display_name}
        </a>
      </li>
    ))}
  </ul>
);
@withRouter
@inject('store')
@observer
class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      garageList: [],
      showModal: false,
      popoverOpen: false,
      isCategoryPage: false,
      showParts: false,
      path: '/',
    };
  }

  componentDidMount() {
    this.setState({ path: this.props.location.pathname });
    if (this.props.location.pathname === '/') {
      this.setState({ showParts: true });
    }
    if (this.props.store.auth) {
      this.getGarageList();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ path: this.props.location.pathname });
      if (this.props.location.pathname === '/') {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ showParts: true });
      } else {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ showParts: false });
      }
    }
  }

  // for first time call
  // componentWillReceiveProps() {
  //   if (this.props.store.auth) {
  //     this.getGarageList();
  //   }
  // }

  // garage list for dropdown
  // garbase list need to be remove

  // removeGarbeList = () => {
  //   const that = this;
  //   API.put('/user/garage/')
  //     .then(() => {
  //       that.getGarageList();
  //     })
  //     .catch((error) => {
  //       //console.log(error);
  //     });
  // };

  getGarageList = () => {
    const that = this;
    API.get('/user/garage/')
      .then((response) => {
        that.setState({
          garageList: response.data,
        });
      })
      .catch(() => {
        // console.log(error);
      });
  };

  getDataGarage = (data) => {
    this.setState({ garageList: data });
  }

  // get the selected existing garage
  // handleGarageItem = (e, item) => {
  //   //console.log(e, item);
  // };

  toggleMenu = () => {
    document.getElementById('mobile-menu').classList.toggle('opened');
    document.querySelector('nav').classList.toggle('opened');
    if (document.querySelector('.garage-link') !== null) {
      document.querySelector('.garage-link').classList.toggle('opened');
    }
  };

  // for mobile
  toggleMenuMobile=() => {
    document.getElementById('mobile-menu-mobile').classList.toggle('opened');
    document.querySelector('.mobile-nav').classList.toggle('opened');
    if (document.querySelector('.garage-link') !== null) {
      document.querySelector('.garage-link').classList.toggle('opened');
    }
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  openModal = () => {
    this.setState({ showModal: true, popoverOpen: false }, () => {
      document.getElementById('dropdown-basic').click();
    });
  };

  handleGaragePopUp = () => {
    const { popoverOpen } = this.state;

    if (window.location.pathname === '/category') {
      this.setState({
        isCategoryPage: true,
      });
    }
    this.setState({
      popoverOpen: !popoverOpen,
    });
  };

  handleGarageItem = (item) => {
    const { popoverOpen, isCategoryPage } = this.state;
    document.getElementById('dropdown-basic').click();
    localStorage.setItem('engine_config', JSON.stringify(item.engine_config));
    this.setState(
      {
        popoverOpen: !popoverOpen,
      },
      () => {
        if (isCategoryPage) {
          window.location.reload();
        }
      },
    );
  };

  handleRemoveItem = (e, item) => {
    const that = this;
    // eslint-disable-next-line no-alert
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Want to remove this item?')) {
      API.delete(`/user/garage/${item.id}/`)
        .then(() => {
          that.handleGaragePopUp();
          that.getGarageList();
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }

  handlePrimary = (e, item) => {
    const that = this;
    // eslint-disable-next-line no-alert
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Want to make this as your primary car?')) {
      API.put(`/user/garage/${item.id}/`, { is_primary: true })
        .then(() => {
          // that.handleGaragePopUp();
          that.getGarageList();
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }

  gotoCommodities = () => {
    if (sessionStorage.getItem('category_selected')) {
      sessionStorage.removeItem('category_selected');
    }
    this.props.history.push('/category?topCategoryId=300000009');
  }

  showParts = () => {
    const { showParts } = this.state;
    this.setState({ showParts: !showParts });
  }

  render() {
    const {
      garageList, showModal, showParts, path,
    } = this.state;
    const garageDropdown = (garageList || []).map((item) => (
      <li>
        {item.is_primary ? (
          <a href="javascript:void(0);" className="primary-my-garage" title="Primary car">
            <img src={fillStar} alt="primary" />
          </a>
        ) : (
          <a href="javascript:void(0);" className="primary-my-garage" title="Make this as primary car" onClick={(e) => this.handlePrimary(e, item)}>
            <img src={lineStar} alt="not-primary" />
          </a>
        )}
        <Link
          onClick={() => this.handleGarageItem(item)}
          to={`/category?base_vehicle_id=${item.base_vehicle_id}&engine_id=${item.engine_id}&engine_name=${item.engine_name}&year=${item.name}&topCategoryId=300000001`}
        >
          {`${item.name}-${item.engine_name}`}
        </Link>
        <a href="javascript:void(0);" className="remove-my-garage" onClick={(e) => this.handleRemoveItem(e, item)}>
          <img src={trash} alt="trash-icon" />
        </a>
      </li>
    ));
    return (
      <>
        <Modal
          isOpen={showModal}
          contentLabel="garage:"
          className="garage-modal"
          overlayClassName="garage-modal-overlay"
        >
          <PartSearch garage="my-garage" closeModal={() => this.closeModal()} path={this.props.history} getDataGarage={this.getDataGarage} />
          <button type="button" className="close-button" onClick={() => this.closeModal()}>
            <img src={closeBlack} alt="close" />
          </button>
        </Modal>
        <div className="nav-bar">
          <div className="container">
            <div className="row final">
              {/* <div className="row"> */}
              <div className="col-lg-10 container1">
                {/* <div className="col-xs-10 col-md-10 col-lg-10 "> */}
                <div className="mobile-menu-wrap ">
                  {/* <div className="mobile-menu-wrap d-sm-none d-md-none d-lg-block d-none"> */}
                  <button
                    type="button"
                    className="menu"
                    id="mobile-menu-mobile" // mobile-menu
                    onClick={() => this.toggleMenuMobile()}
                  >
                    <svg width="60" height="60" viewBox="0 0 100 100">
                      <path
                        className="line line1"
                        d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                      />
                      <path className="line line2" d="M 20,50 H 80" />
                      <path
                        className="line line3"
                        d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                      />
                    </svg>
                  </button>
                </div>

                {/* *************************************ONLY IN MOBILE VIEW ************* */}
                {/* <div className="mobile-menu-wrap d-sm-block d-md-block d-lg-none">
                  <button
                    type="button"
                    className="menu"
                    id="mobile-menu-mobile"
                    onClick={() => this.toggleMenuMobile()}
                  >
                    <svg width="60" height="60" viewBox="0 0 100 100">
                      <path
                        className="line line1"
                        d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                      />
                      <path className="line line2" d="M 20,50 H 80" />
                      <path
                        className="line line3"
                        d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                      />
                    </svg>
                  </button>
                </div> */}
                {/* ********************************************************************** */}
                {/* <nav d-sm-none d-md-none d-lg-block> */}
                <nav>
                  <ul>
                    {this.props.store.siteData?.menu?.items?.map((m) => (
                      <li style={{ height: '45px' }} className={m?.items.length > 0 && 'has-submenu'}>
                        <a href={m.link} target={m.target}>
                          {m.display_name}
                        </a>
                        {m?.items.length > 0 && subMenu(m?.items)}
                      </li>
                    ))}
                    {path !== '/category' && (
                    <>
                      <li className="featured-link">
                        <a href="javascript:void(0)" title="Vehicle" onClick={() => this.showParts()}>
                          <img src={car} alt="Vehicle" />
                          <span>Vehicle</span>
                        </a>
                      </li>
                      <li className="featured-link">
                        <a href="javascript:void(0)" title="Commodities" onClick={() => this.gotoCommodities()}>
                          <img src={carParts} alt="Commodities" />
                          <span>Commodities</span>
                        </a>
                      </li>
                    </>
                    )}
                  </ul>
                </nav>
              </div>
              {this.props.store.auth && (
              <div className="col-xs-2 col-md-10  col-lg-2 container2">
                {/* <Link to="/my-garage" className="garage-link">
                  <img src={garageIcon} alt="garage-icon" />
                  <span>My Garage</span>
                </Link> */}
                {/* <Button id="PopoverLegacy" type="button" className="garage-link">
                    My Garage
                  </Button> */}
                {/* <UncontrolledPopover
                    placement="bottom"
                    target="PopoverLegacy"
                    // used trigger instead of state to manage outside click
                    // trigger="legacy"
                    isOpen={popoverOpen}
                    toggle={this.handleGaragePopUp}
                  >
                    <PopoverHeader>Garage List</PopoverHeader>
                    <PopoverBody>
                      <ul className="garage-list">{garageDropdown}</ul>
                      <a
                        href="javascript:void(0);"
                        onClick={() => this.openModal()}
                        className="add-garage-link"
                      >
                        <span>Add New</span>
                      </a>
                    </PopoverBody>
                  </UncontrolledPopover> */}
                <Dropdown onToggle={this.handleGaragePopUp}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic" class="garage-link btn btn-secondary">
                    My Garage
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="popover">
                    <h3 className="popover-header">Garage List</h3>
                    <div className="popover-body">
                      <ul className="garage-list">{garageDropdown}</ul>
                      <a
                        href="javascript:void(0);"
                        onClick={() => this.openModal()}
                        className="add-garage-link"
                      >
                        <span>Add New</span>
                      </a>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              )}
            </div>
            {/* ***********************************MOVILE VIEW MENU*********************** */}
            <div className="d-sm-block d-md-block d-lg-none">
              {' '}
              <nav className="mobile-nav">
                <ul>
                  {this.props.store.siteData?.menu?.items?.map((m) => (
                    <li className={m?.items.length > 0 && 'has-submenu'}>
                      <a href={m.link} target={m.target}>
                        {m.display_name}
                      </a>
                      {m?.items.length > 0 && subMenu(m?.items)}
                    </li>
                  ))}
                  {path !== '/category' && (
                  <>
                    <li className="featured-link">
                      <a href="javascript:void(0)" title="Vehicle" onClick={() => this.showParts()}>
                        <img src={car} alt="Vehicle" />
                        <span>Vehicle</span>
                      </a>
                    </li>
                    <li className="featured-link">
                      <a href="javascript:void(0)" title="Commodities" onClick={() => this.gotoCommodities()}>
                        <img src={carParts} alt="Commodities" />
                        <span>Commodities</span>
                      </a>
                    </li>
                  </>
                  )}
                </ul>
              </nav>
            </div>
            {/* ****************************************************************************** */}
          </div>
        </div>
        {showParts && <PartSearch path={this.props.history} getDataGarage={this.getDataGarage} />}
      </>
    );
  }
}

export default Nav;
