import axios from 'axios';
import axiosRetry from 'axios-retry';

const API = axios.create({
  // baseURL: 'https://2a2c1d3de098.ngrok.io',
  // baseURL: 'https://shopdev.fisherautoparts.com/api',
  baseURL: `${window.location.protocol}//${window.location.hostname}/api`,
});

axiosRetry(API, { retries: 3 });

// Obtain the fresh token each time the function is called
const getAccessToken = () => localStorage.getItem('federated_access');

// intercept the request
API.interceptors.request.use((request) => {
  request.headers.Authorization = `Bearer ${getAccessToken()}`;
  request.headers['x-timezone'] = new Date().getTimezoneOffset();
  return request;
});

/**
 * @description axios multiple api refresh
 */
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

API.interceptors.response.use((response) => response, (error) => {
  const originalRequest = error.config;

  // eslint-disable-next-line no-underscore-dangle
  if (error.response.status === 401 && error.response.config.url !== '/user/login/' && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise(((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })).then((token) => {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return axios(originalRequest);
      }).catch((err) => Promise.reject(err));
    }

    // eslint-disable-next-line no-underscore-dangle
    originalRequest._retry = true;
    isRefreshing = true;

    const refreshToken = window.localStorage.getItem('federated_refresh');
    return new Promise(((resolve, reject) => {
      API.post('/user/token/refresh/', { refresh: refreshToken })
        .then(({ data }) => {
          window.localStorage.setItem('federated_access', data.access);
          // window.localStorage.setItem('federated_refresh', data.refreshToken);
          API.defaults.headers.common.Authorization = `Bearer ${data.access}`;
          originalRequest.headers.Authorization = `Bearer ${data.access}`;
          processQueue(null, data.access);
          resolve(API(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .finally(() => { isRefreshing = false; });
    }));
  }

  return Promise.reject(error);
});

export { API as default };
