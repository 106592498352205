import React from 'react';
import loading from '../../assets/images/loading.gif';
import disconnect from '../../assets/images/disconnect.png';

const RouteLoading = ({ error }) => {
  if (error) {
    return (
      <div className="route-loading error">
        <div className="container">
          <div className="row">
            <div className="col">
              <h3>
                <img src={disconnect} alt="disconnect" />
                Oh no! Please check your network connection...
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="route-loading">
      <div className="container">
        <div className="row">
          <div className="col">
            <h3>
              <img src={loading} alt="loading" />
              Loading...
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

const Loading = () => (
  <h3 className="box-loading">
    <img src={loading} alt="loading" />
    Loading...
  </h3>
);

const LoadingError = () => (
  <h3 className="box-loading error">
    <img src={disconnect} alt="disconnect" />
    Something went wrong :(
  </h3>
);

const FullPageLoading = () => <div className="loading">Loading&#8230;</div>;

const FullPageOrderLoading = () => <div className="loading"><h3>Processing....</h3></div>;

export {
  RouteLoading, Loading, LoadingError, FullPageLoading, FullPageOrderLoading,
};
