/* eslint-disable max-len */
import React from 'react';
import { observer, inject } from 'mobx-react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Nav from './Nav';
import {
  Home,
  About,
  Category,
  ThankYou,
  Login,
  ForgotPassword,
  ResetPassword,
  SignUp,
  Profile,
  ProfileEdit,
  ChangePassword,
  ProductListing,
  ProductDetails,
  Cart,
  MyOrders,
  OrderDetails,
  Checkout,
  MyGarage,
  BillingAddress,
  My404,
  SetPassword,
} from './Route';

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (auth === true ? <Component {...props} /> : <Redirect to="/login" />)}
  />
);

const PublicRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (auth !== true ? <Component {...props} /> : <Redirect to="/" />)}
  />
);

@inject('store')
@observer
class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Nav />
        <Switch>
          {/* global */}
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/category" component={Category} />
          <Route exact path="/product-listing" component={ProductListing} />
          <Route exact path="/product-details/:id" component={ProductDetails} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/404" component={My404} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/thank-you/:id/:store" component={ThankYou} />
          <Route exact path="/login/:id" component={Login} />

          {/* only public */}
          <PublicRoute exact auth={this.props.store.auth} path="/login" component={Login} />

          <PublicRoute exact auth={this.props.store.auth} path="/sign-up" component={SignUp} />
          <PublicRoute
            exact
            auth={this.props.store.auth}
            path="/forgot-password"
            component={ForgotPassword}
          />
          <PublicRoute
            exact
            auth={this.props.store.auth}
            path="/reset-password/:id"
            component={ResetPassword}
          />
          <PublicRoute
            exact
            auth={this.props.store.auth}
            path="/set-password/:id"
            component={SetPassword}
          />

          {/* only private */}
          <PrivateRoute exact auth={this.props.store.auth} path="/profile" component={Profile} />
          <PrivateRoute
            exact
            auth={this.props.store.auth}
            path="/profile-edit/:id"
            component={ProfileEdit}
          />
          <PrivateRoute
            exact
            auth={this.props.store.auth}
            path="/change-password"
            component={ChangePassword}
          />
          <PrivateRoute exact auth={this.props.store.auth} path="/my-orders" component={MyOrders} />
          <PrivateRoute
            exact
            auth={this.props.store.auth}
            path="/order-details/:id"
            component={OrderDetails}
          />
          {/* <PrivateRoute exact auth={this.props.store.auth} path="/checkout" component={Checkout} /> */}
          {/* <PrivateRoute exact auth={this.props.store.auth} path="/thank-you/:id" component={ThankYou} /> */}
          <PrivateRoute exact auth={this.props.store.auth} path="/my-garage" component={MyGarage} />
          <PrivateRoute exact auth={this.props.store.auth} path="/billing-address" component={BillingAddress} />

          {/* 404 redirect */}
          <Redirect from="*" to="/404" />
        </Switch>
      </>
    );
  }
}

export default AppRouter;
