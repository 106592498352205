import Loadable from 'react-loadable';
import { RouteLoading } from '../components/loading';

const Home = Loadable({
  loader: () => import('../modules/Home'),
  loading: RouteLoading,
});

const About = Loadable({
  loader: () => import('../modules/About'),
  loading: RouteLoading,
});

const Category = Loadable({
  loader: () => import('../modules/Category'),
  loading: RouteLoading,
});

const ThankYou = Loadable({
  loader: () => import('../modules/ThankYou'),
  loading: RouteLoading,
});

const Profile = Loadable({
  loader: () => import('../modules/Profile'),
  loading: RouteLoading,
});

const ProfileEdit = Loadable({
  loader: () => import('../modules/ProfileEdit'),
  loading: RouteLoading,
});

const ChangePassword = Loadable({
  loader: () => import('../modules/ChangePassword'),
  loading: RouteLoading,
});

const Login = Loadable({
  loader: () => import('../modules/Login'),
  loading: RouteLoading,
});

const ForgotPassword = Loadable({
  loader: () => import('../modules/ForgotPassword'),
  loading: RouteLoading,
});
const ResetPassword = Loadable({
  loader: () => import('../modules/ResetPassword'),
  loading: RouteLoading,
});

const SetPassword = Loadable({
  loader: () => import('../modules/SetPassword'),
  loading: RouteLoading,
});

const SignUp = Loadable({
  loader: () => import('../modules/SignUp'),
  loading: RouteLoading,
});

const ProductListing = Loadable({
  loader: () => import('../modules/ProductListing'),
  loading: RouteLoading,
});

const ProductDetails = Loadable({
  loader: () => import('../modules/ProductDetails'),
  loading: RouteLoading,
});

const Cart = Loadable({
  loader: () => import('../modules/Cart'),
  loading: RouteLoading,
});

const MyOrders = Loadable({
  loader: () => import('../modules/MyOrders'),
  loading: RouteLoading,
});

const OrderDetails = Loadable({
  loader: () => import('../modules/OrderDetails'),
  loading: RouteLoading,
});

const Checkout = Loadable({
  loader: () => import('../modules/Checkout'),
  loading: RouteLoading,
});

const MyGarage = Loadable({
  loader: () => import('../modules/MyGarage'),
  loading: RouteLoading,
});

const BillingAddress = Loadable({
  loader: () => import('../modules/BillingAddress'),
  loading: RouteLoading,
});

const My404 = Loadable({
  loader: () => import('../modules/My404'),
  loading: RouteLoading,
});

export {
  Home,
  About,
  Category,
  ThankYou,
  Profile,
  ProfileEdit,
  ChangePassword,
  Login,
  SignUp,
  ProductListing,
  ProductDetails,
  Cart,
  MyOrders,
  OrderDetails,
  Checkout,
  ForgotPassword,
  ResetPassword,
  SetPassword,
  MyGarage,
  BillingAddress,
  My404,
};
