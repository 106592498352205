import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { NavLink } from 'react-router-dom';

// import siteLogo from '../../assets/images/site-logo.png';

@inject('store')
@observer
class Logo extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    return (
      <>
        <NavLink to="/">
          <img alt="" className="main-logo img-fluid" src={this.props.store.siteData?.detail?.logo} />
        </NavLink>
      </>
    );
  }
}

export default Logo;
