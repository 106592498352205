import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { FullPageLoading } from '../components/loading';
import ScrollToTop from '../components/scroll-to-top/ScrollToTop';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import AppRouter from '../router/index';

@inject('store')
@observer
class Base extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getProductList = (value) => {
    const productListRoute = `/product-listing?searchValue=${value}`;
    if (window.location.pathname.includes('/product-listing')) {
      window.location.replace(productListRoute);
    } else {
      window.location.assign(productListRoute);
    }
  }

  render() {
    return (
      <Router>
        <ScrollToTop>
          {this.props.store.hasSiteData && (
            <>
              <Helmet>
                <title>{`Shop | ${this.props.store.siteData.detail?.company_name}`}</title>
                <style type="text/css">{`${this.props.store.siteData.stylesheet}`}</style>
              </Helmet>
              <Header searchProduct={this.getProductList} />
              <AppRouter />
              <Footer />
            </>
          )}
          <ToastContainer />
          {
            this.props.store.hasSiteData === false
            && (!(window.location?.pathname.split('/').includes('product-details')) && !(window.location?.pathname.split('/').includes('product-listing')))
            && <FullPageLoading />
          }
          {this.props.store.hasSiteData === null && (
          <div className="thank-you-area">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="thank-block">
                    <div className="thank-you-logo">
                      <p className="thank-you-heading">400</p>
                      <span className="thank-you-msg">
                        {this.props.store.siteData}
                        !
                      </span>
                      <p />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          )}
        </ScrollToTop>
      </Router>
    );
  }
}

export default Base;
