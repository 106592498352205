import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

@inject('store')
@observer
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  createMarkup = () => ({ __html: this.props.store.siteData.detail?.footer_content })

  render() {
    return (
      <>
        <footer className={`footer ${window.location.pathname.replace(/\//g, '')}`}>
          <div className="container footer-section">
            <div dangerouslySetInnerHTML={this.createMarkup()} />
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
