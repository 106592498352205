import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Alert } from 'reactstrap';

import 'react-toastify/dist/ReactToastify.css';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import API from '../../api';
import car from '../../assets/images/car.png';

// const Icon = <FontAwesomeIcon icon={faChevronDown} color={"#EB8C00"} />;

@inject('store')
@observer
class PartSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleGroup: [],
      vehicleGroupXhr: null,
      vechicleYear: [],
      vechicleYearXhr: null,
      vechicleMake: [],
      vechicleMakeXhr: null,
      vechicleModel: [],
      vechicleModelXhr: null,
      vechicleEngine: [],
      vechicleEngineXhr: null,
      success: false,
      vehicle_group_id: 200,
      garageAddData: '',
      duplicateVech: null,
    };
  }

  componentDidMount() {
    this.getVehicleGroup();
    this.getVehicleYear();
  }

  // list of vehicles
  getVehicleGroup = () => {
    const that = this;
    this.setState({ vehicleGroupXhr: true });
    API.get('/catalog/vehicles/groups/')
      .then((response) => {
        that.setState({
          vehicleGroup: response.data.groups,
        });
        this.setState({ vehicleGroupXhr: false });
      })
      .catch(() => {
        // console.log(error);
        this.setState({ vehicleGroupXhr: false });
      });
  };

  // list of vehicle years
  getVehicleYear = () => {
    const that = this;
    const id = {
      vehicle_group_id: this.state.vehicle_group_id,
    };
    this.setState({ vechicleYearXhr: true });
    API.post('/catalog/vehicles/years/', id)
      .then((response) => {
        that.setState({ vechicleYear: response.data.years });
        that.setState({ vechicleYearXhr: false });
      })
      .catch(() => {
        // console.log(error);
        that.setState({ vechicleYearXhr: false });
      });
  };

  // get list of years
  getVehicleMake = () => {
    const that = this;
    const data = {
      vehicle_group_id: this.state.vehicle_group_id,
      year_id: this.state.year_id,
    };
    this.setState({ vechicleMakeXhr: true });
    API.post('/catalog/vehicles/makes/', data)
      .then((response) => {
        that.setState({ vechicleMake: response.data.makes });
        that.setState({ vechicleMakeXhr: false });
      })
      .catch(() => {
        // console.log(error);
        that.setState({ vechicleMakeXhr: false });
      });
  };

  // get list of models
  getVehicleModel = () => {
    const that = this;
    const data = {
      vehicle_group_id: this.state.vehicle_group_id,
      year_id: this.state.year_id,
      make_id: this.state.make_id,
    };
    this.setState({ vechicleModelXhr: true });
    API.post('/catalog/vehicles/models/', data)
      .then((response) => {
        that.setState({ vechicleModel: response.data.models });
        that.setState({ vechicleModelXhr: false });
      })
      .catch(() => {
        // console.log(error);
        that.setState({ vechicleModelXhr: false });
      });
  };

  getGarageList = () => {
    API.get('/user/garage/')
      .then((response) => {
        this.props.getDataGarage(response.data);
      })
      .catch(() => {
        // console.log(error);
      });
  };

  // get list of engines
  getVehicleEngine = () => {
    const that = this;
    const data = {
      vehicle_group_id: this.state.vehicle_group_id,
      year_id: this.state.year_id,
      make_id: this.state.make_id,
      model_id: this.state.model_id,
    };
    this.setState({ vechicleEngineXhr: true });
    API.post('/catalog/vehicles/engine/', data)
      .then((response) => {
        that.setState({ vechicleEngine: response.data.engines });
        that.setState({ vechicleEngineXhr: false });
        // AUTOMATICALLY PROCEED OF ONLY ONE ENGNE FOUND
        if ((response.data.engines).length === 1) {
          const e = {
            target: { value: `${(response.data.engines[0]).base_vehicle_id}---${(response.data.engines[0]).engine_id}---${(response.data.engines[0]).engine_name}---${JSON.stringify((response.data.engines[0]).engine_config)}` },
          };

          this.handleChangeEngine(e);
        }
        /// ///////////////
      })
      .catch(() => {
        // console.log(error);
        that.setState({ vechicleEngineXhr: false });
      });
  };

  handleChangeGroup = (e) => {
    const id = e.target.value;
    this.setState(
      {
        vehicle_group_id: id,
        // vehicle_group_name: e.target[e.target.selectedIndex].getAttribute('data-name'),
      },
      () => {
        this.getVehicleYear();
      },
    );
  };

  handleChangeYear = (e) => {
    const year = e.target.value;
    this.setState(
      {
        year_id: year,
        year_name: e.target[e.target.selectedIndex].getAttribute('data-name'),
      },
      () => {
        this.getVehicleMake();
      },
    );
  };

  handleChangeMake = (e) => {
    const make = e.target.value;
    this.setState(
      {
        make_id: make,
        make_name: e.target[e.target.selectedIndex].getAttribute('data-name'),
      },
      () => {
        this.getVehicleModel();
      },
    );
  };

  handleChangeModel = (e) => {
    const model = e.target.value;
    const ec = e.target[e.target.selectedIndex].getAttribute('data-ec');
    const base = JSON.parse(e.target[e.target.selectedIndex].getAttribute('data-base'));

    // console.log(ec, base);
    this.setState(
      {
        model_id: model,
        model_name: e.target[e.target.selectedIndex].getAttribute('data-name'),
      },
      () => {
        if (ec * 1 !== 0) {
          this.getVehicleEngine();
        } else {
          localStorage.setItem('engine_config', JSON.stringify(base));

          this.setState(
            {
              base_vehilce_id: base.basevehicle,
            },
            () => {
              const catRoute = `/category?base_vehicle_id=${this.state.base_vehilce_id}&engine_id=&engine_name=&year=${`${this.state.year_name} ${this.state.make_name} ${this.state.model_name}&topCategoryId=300000001`}`;
              sessionStorage.setItem('fe-cat-route', catRoute);
              this.props.path.push(catRoute);
            },
          );
        }
      },
    );
  };

  handleChangeEngine = (e) => {
    localStorage.setItem('engine_config', e.target.value.split('---')[3]);
    // IF THERE WILL BE A SEPARATE ROUTE THEN EXECUTE THIS BLOCK
    // if (this.props?.garage === 'my-garage') {
    const data = {
      base_vehicle_id: e.target.value.split('---')[0],
      name: `${this.state.year_name} ${this.state.make_name} ${this.state.model_name}`,
      engine_name: e.target.value.split('---')[2],
      engine_id: e.target.value.split('---')[1],
      engine_config: JSON.parse(e.target.value.split('---')[3]),
    };
    const that = this;
    API.post('/user/garage/', data)
      .then(() => {
        // console.log(response);
        that.setState({
          success: true,
        });
        setTimeout(() => {
          that.setState({ success: false });
        }, 1500);
        this.getGarageList();
      })
      .catch(() => {
        // console.log(error);
      });
    // return false;
    // }
    const engine_details = e.target.value.split('---');
    this.setState(
      {
        base_vehilce_id: engine_details[0],
        engine_id: engine_details[1],
        engine_name: engine_details[2],
      },
      () => {
        const catRoute = `/category?base_vehicle_id=${this.state.base_vehilce_id}&engine_id=${this.state.engine_id}&engine_name=${this.state.engine_name}&year=${`${this.state.year_name} ${this.state.make_name} ${this.state.model_name}&topCategoryId=300000001`}`;
        sessionStorage.setItem('fe-cat-route', catRoute);
        this.props.path.push(catRoute);
      },
    );
    return true;
  };

  handleChangeEngineGarage = (e) => {
    const { year_name, make_name, model_name } = this.state;
    const data = {
      base_vehicle_id: e.target.value.split('---')[0],
      name: `${year_name} ${make_name} ${model_name}`,
      engine_name: e.target.value.split('---')[2],
      engine_id: e.target.value.split('---')[1],
      engine_config: JSON.parse(e.target.value.split('---')[3]),
    };
    this.setState({ garageAddData: data });
  }

  addGarageSubmit = () => {
    const { garageAddData } = this.state;

    const that = this;
    API.post('/user/garage/', garageAddData)
      .then((response) => {
        if (response.data.message === 'Duplicate vehicle added.') {
          this.setState({
            duplicateVech: response.data,
            garageAddData: '',
            vehicleGroup: [],
            vehicleGroupXhr: null,
            vechicleYear: [],
            vechicleYearXhr: null,
            vechicleMake: [],
            vechicleMakeXhr: null,
            vechicleModel: [],
            vechicleModelXhr: null,
            vechicleEngine: [],
            vechicleEngineXhr: null,
            vehicle_group_id: 200,
          });
          that.componentDidMount();
        } else {
          that.setState({
            success: true,
            garageAddData: '',
            vehicleGroup: [],
            vehicleGroupXhr: null,
            vechicleYear: [],
            vechicleYearXhr: null,
            vechicleMake: [],
            vechicleMakeXhr: null,
            vechicleModel: [],
            vechicleModelXhr: null,
            vechicleEngine: [],
            vechicleEngineXhr: null,
            vehicle_group_id: 200,
          });
          that.componentDidMount();
          setTimeout(() => {
            that.setState({ success: false });
          }, 2500);
          this.getGarageList();
        }
      })
      .catch(() => {
        // console.log(error);
      });
  };

  openCategory = () => {
    const { duplicateVech } = this.state;
    localStorage.setItem('engine_config', duplicateVech.engine_config);
    // IF THERE WILL BE A SEPARATE ROUTE THEN EXECUTE THIS BLOCK
    // const data = {
    //   base_vehicle_id: duplicateVech.base_vehicle_id,
    //   name: duplicateVech.name,
    //   engine_name: duplicateVech.engine_name,
    //   engine_id: duplicateVech.engine_id,
    //   engine_config: duplicateVech.engine_config,
    // };
    this.setState(
      {
        base_vehilce_id: duplicateVech.base_vehicle_id,
        engine_id: duplicateVech.engine_id,
        engine_name: duplicateVech.engine_name,
      },
      () => {
        const catRoute = `/category?base_vehicle_id=${this.state.base_vehilce_id}&engine_id=${this.state.engine_id}&engine_name=${this.state.engine_name}&year=${`${duplicateVech.name}&topCategoryId=300000001`}`;
        sessionStorage.setItem('fe-cat-route', catRoute);
        this.props.closeModal();
        this.props.path.push(catRoute);
      },
    );
  };

  render() {
    const {
      vehicleGroup,
      vechicleYear,
      vechicleMake,
      vechicleModel,
      vechicleEngine,
      vehicleGroupXhr,
      vechicleYearXhr,
      vechicleMakeXhr,
      vechicleModelXhr,
      vechicleEngineXhr,
      success,
      garageAddData,
      duplicateVech,
    } = this.state;

    const vehicleList = (vehicleGroup || []).map((item) => (
      <option
        key={item.vehicle_group_id}
        value={item.vehicle_group_id}
        selected={item.vehicle_group_id === 200}
        data-name={item.vehicle_group_name}
      >
        {item.vehicle_group_name}
      </option>
    ));
    const vehicleYearList = (vechicleYear || []).map((item) => (
      <option key={item.year_id} value={item.year_id} data-name={item.year_name}>
        {item.year_name}
      </option>
    ));
    const vechicleMakeList = (vechicleMake || []).map((item) => (
      <option key={item.make_id} value={item.make_id} data-name={item.make_name}>
        {item.make_name}
      </option>
    ));
    const vechicleModelList = (vechicleModel || []).map((item) => (
      <option
        key={item.model_id}
        value={item.model_id}
        data-name={item.model_name}
        data-ec={item.engine_count}
        data-base={JSON.stringify(item.base_config)}
      >
        {item.model_name}
      </option>
    ));
    const vechicleEngineList = (vechicleEngine || []).map((item) => (
      <option
        key={item.engine_id}
        value={`${item.base_vehicle_id}---${item.engine_id}---${item.engine_name}---${JSON.stringify(item.engine_config)}`}
        data-name={item.engine_name}
      >
        {item.engine_name}
      </option>
    ));
    return (
      <div className={`parts-wrap ${this.props.garage}`}>
        <div className="container">
          <div className="row">
            <div className="col">
              {this.props.garage === undefined ? (
                <span>
                  <img src={car} alt="Vehicle" width="19px" />
                  &nbsp;&nbsp; Select your vehicle
                </span>
              ) : (
                <span>Add Vehicle:</span>
              )}
            </div>
          </div>
          <div
            className={`${
              this.props.garage === 'my-garage'
                ? 'row'
                : 'row row-cols-1 row-cols-sm-2 row-cols-md-5'
            }`}
          >
            <div className={`${this.props.garage === 'my-garage' ? 'col-md-6' : 'col'}`}>
              <select onChange={this.handleChangeGroup} disabled={vehicleGroupXhr !== false}>
                {vehicleGroupXhr ? (
                  <option>Loading ...</option>
                ) : (
                  <>
                    {vehicleGroupXhr === false && (
                      <>
                        {/* <option value="0">Select Group</option> */}
                        {vehicleList}
                      </>
                    )}
                  </>
                )}
              </select>
            </div>
            <div className={`${this.props.garage === 'my-garage' ? 'col-md-6' : 'col'}`}>
              <select onChange={this.handleChangeYear} disabled={vechicleYearXhr !== false}>
                {vechicleYearXhr == null && <option value="0">Select Year</option>}
                {vechicleYearXhr ? (
                  <option>Loading ...</option>
                ) : (
                  <>
                    {vechicleYearXhr === false && (
                      <>
                        <option value="0">Select Year</option>
                        {vehicleYearList}
                      </>
                    )}
                  </>
                )}
              </select>
            </div>
            <div className={`${this.props.garage === 'my-garage' ? 'col-md-6' : 'col'}`}>
              <select onChange={this.handleChangeMake} disabled={vechicleMakeXhr !== false}>
                {vechicleMakeXhr == null && <option value="0">Select Make</option>}
                {vechicleMakeXhr ? (
                  <option>Loading ...</option>
                ) : (
                  <>
                    {vechicleMakeXhr === false && (
                      <>
                        <option value="0">Select Make</option>
                        {vechicleMakeList}
                      </>
                    )}
                  </>
                )}
              </select>
            </div>
            <div className={`${this.props.garage === 'my-garage' ? 'col-md-6' : 'col'}`}>
              <select onChange={this.handleChangeModel} disabled={vechicleModelXhr !== false}>
                {vechicleModelXhr == null && <option value="0">Select Model</option>}
                {vechicleModelXhr ? (
                  <option>Loading ...</option>
                ) : (
                  <>
                    {vechicleModelXhr === false && (
                      <>
                        <option value="0">Select Model</option>
                        {vechicleModelList}
                      </>
                    )}
                  </>
                )}
              </select>
            </div>
            <div className={`${this.props.garage === 'my-garage' ? 'col-md-6' : 'col'}`}>
              <select id="select-engine" onChange={this.props.garage === 'my-garage' ? this.handleChangeEngineGarage : this.handleChangeEngine} disabled={vechicleEngineXhr !== false}>
                {vechicleEngineXhr == null && <option value="0">Select Engine</option>}
                {vechicleEngineXhr ? (
                  <option>Loading ...</option>
                ) : (
                  <>
                    {vechicleEngineXhr === false && (
                      <>
                        <option value="0">Select Engine</option>
                        {vechicleEngineList}
                      </>
                    )}
                  </>
                )}
              </select>
            </div>
            {this.props.garage === 'my-garage' && (
              <div className="col-md-6">
                <button type="button" disabled={garageAddData === ''} className="btn-base add-vehicle" onClick={this.addGarageSubmit}>Add Vehicle</button>
              </div>
            )}
            {/* <button type="button" color="primary" className="my-toggle">{Icon}</button> */}

          </div>
          {success && <Alert color="success">Vehicle added successfully</Alert>}
          {duplicateVech && (
            <p className="duplicate-vech">
              The Vehicle you have selected is already in your garage.
              <a href="javascript:void(0)" onClick={() => this.openCategory()}> Click here </a>
              to shop with this vehicle.
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default PartSearch;
