import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import axios from 'axios';

import Logo from './Logo';
import TopIconLinks from './TopIconLinks';

import baseLogo from '../../assets/images/main-logo.png';
import search from '../../assets/images/search-icon.png';

@inject('store')
@observer
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sVal: '',
    };

    if (window.performance) {
      if (performance.navigation.type === 1) {
        // alert('This page is reloaded');
      } else if (
        localStorage.getItem('federated_cord') != null && window.location.pathname === '/' && sessionStorage.getItem('federatedSession')) {
        this.getGeoLocation('fromHomeSession');
      }
    }
  }

  componentDidMount() {
    if (window.location.search.includes('?searchValue')) {
      const arr = window.location.search.split('=');
      this.setState({ sVal: decodeURI(arr[1]) });
    }
    if (localStorage.getItem('federated_cord') === null) {
      this.getGeoLocation();
    }
  }

  reverseGeocodingWithGoogle = (latitude, longitude) => {
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?
        latlng=${latitude},${longitude}&key={GOOGLE_MAP_KEY}`)
      .then((res) => res.json())
      .then(() => {
        // console.log("User's Location Info: ", response);
      })
      .catch(() => {
        // console.log('Request failed.  Returned status of', status);
      });
  };

  setCord = (lat, lng) => {
    localStorage.setItem('federated_cord', JSON.stringify({ lat, lng }));
  }

  ipLookup = () => {
    const that = this;
    axios.get('https://extreme-ip-lookup.com/json/')
      .then((response) => {
        that.setCord(response.data.lat * 1, response.data.lon * 1);

        if (localStorage.getItem('federated_cord') !== null) {
          that.props.store.locationFinder();
        }
      })
      .catch(() => {
        // console.log('We could not find your location');
      });
  };

  getGeoLocation = (data) => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        (position) => {
          this.setCord(position.coords.latitude, position.coords.longitude);
          if (localStorage.getItem('federated_cord') !== null) {
            this.props.store.locationFinder(data);
          }
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            // console.log('you denied me :-(');
            this.ipLookup();
          }
        },
      );
    } else {
      // console.log('Geolocation is not supported by this browser.');
      this.ipLookup();
    }
  };

  searchProduct = (e) => {
    if (e.keyCode === 13) {
      if (e.target.value !== '') {
        this.props.searchProduct(e.target.value);
        if (sessionStorage.getItem('category_selected')) {
          sessionStorage.removeItem('category_selected');
        }
      } else {
        // console.log('same page');
      }
    }
  }

  changeText=(e) => {
    this.setState({ sVal: e.target.value });
  }

  render() {
    return (
      <>
        <header>
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <Logo />
                <div className="mobile-side-utl-area">
                  <TopIconLinks />
                  <img alt="logo" className="base-logo img-fluid" src={baseLogo} />
                </div>
              </div>
              <div className="col-md-7">
                <div className="right-wrap">
                  <div className="search-wrap">
                    <TopIconLinks />
                    <div className="search-input-wrap">
                      <img alt="search" src={search} />
                      <input type="text" value={this.state.sVal} id="search" onChange={(e) => this.changeText(e)} placeholder="Search" onKeyDown={this.searchProduct} />
                    </div>
                  </div>

                  <div className="base-logo-wrap">
                    <img alt="logo" className="img-fluid" src={baseLogo} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Header;
