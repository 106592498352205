import { observable, action } from 'mobx';
import { toast } from 'react-toastify';
import API from './api';

class AppStore {
  @observable auth = false;

  @observable siteName = process.env.REACT_APP_SITE_NAME;

  @observable hasSiteData = false;

  @observable siteData = [];

  @observable fCategories = { categories: [] };

  @observable topSeller = { top_sellers: [] };

  @observable userId = '';

  @observable allCatalog = [];

  @observable centralLocation = { lat: 0, lng: 0 };

  @observable storeAddress = null;

  @observable paymentEnv = process.env.REACT_APP_PAYMENT_ENV;

  @observable guestBillingInfo = {};

  // @observable userValidForTax = false;

  @observable cartCount = 0;

  @observable googleMapKey = 'AIzaSyC9Td1KUEg9GLpqaOvFEgP7wUjViOmiPGs';

  @observable googleMapScript =
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyC9Td1KUEg9GLpqaOvFEgP7wUjViOmiPGs&v=3.exp&libraries=geometry,drawing,places';

  constructor() {
    sessionStorage.setItem('federatedSession', true);
    this.getSiteData();
    this.checkAuthState();

    // get all location related data
    if (localStorage.getItem('federated_cord') !== null) {
      const allAddress = JSON.parse(localStorage.getItem('federated_all_address')) ? JSON.parse(localStorage.getItem('federated_all_address')) : [];
      const cordsCentral = JSON.parse(localStorage.getItem('federated_cords_central')) ? JSON.parse(localStorage.getItem('federated_cords_central')) : { lat: 0, lng: 0 };
      const storeAddress = JSON.parse(localStorage.getItem('federated_store_address')) ? JSON.parse(localStorage.getItem('federated_store_address')) : {};

      this.allCatalog = allAddress;
      this.centralLocation = cordsCentral;
      this.storeAddress = storeAddress;
    }

    if (localStorage.getItem('cartId') !== null && this.auth) {
      const that = this;
      const data = {
        cart_id: localStorage.getItem('cartId'),
        location_id: this.storeAddress?.location_id,
        location_name: this.storeAddress?.display_name,
      };
      API.put('/catalog/cart/add/', data)
        .then((response) => {
          that.getCartCount(response.data.count);
          localStorage.removeItem('cartId');
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.status === 400) {
            localStorage.removeItem('cartId');
            toast.error(error.response.data.error);
          }
        });
    }
    // if (localStorage.getItem('cartId') !== null && !this.auth) {
    //   const that = this;
    //   API.get(`/catalog/tempcart/${localStorage.getItem('cartId')}/`)
    //     .then((response) => {
    //       that.getCartCount(response.data.count);
    //     })
    //     .catch((error) => {
    //       if (error.response.status === 400) {
    //         toast.error(error.response.data.error);
    //       }
    //     });
    // }
    // if (localStorage.getItem('cartItems') === null) {
    //   localStorage.setItem('cartItems', JSON.stringify([]));
    // } else {
    //   this.getCartCount(JSON.parse(localStorage.getItem('cartItems')).length);

    //   // sending session cart
    //   if (JSON.parse(localStorage.getItem('cartItems')).length > 0 && this.auth) {

    // }
  }

  @action getSiteData() {
    API.get('/catalog/settings/all/')
      .then((response) => {
        this.siteData = [];
        this.hasSiteData = true;
        this.siteData = response.data;
      })
      .catch(() => {
        this.hasSiteData = null;
        this.siteData = 'We are unable to load this site due to a server error. Please try again later.';
        // console.log(error);
      });
  }

  @action getFeaturedCategories = () => {
    API.get('/catalog/settings/featured-categories/')
      .then((response) => {
        this.fCategories = response.data;
      })
      .catch(() => {
        // console.log(error);
      });
  }

  @action getTopSellers = () => {
    API.get('/catalog/settings/top-sellers/')
      .then((response) => {
        this.topSeller = response.data;
      })
      .catch(() => {
        // console.log(error);
      });
  }

  @action changeAuth(state) {
    this.auth = state;
  }

  @action checkAuthState() {
    if (
      localStorage.getItem('federated_access') == null
      && localStorage.getItem('federated_refresh') == null
      && localStorage.getItem('federated_user_id') == null
    ) {
      this.auth = false;
    } else {
      this.auth = true;
    }
  }

  @action makeLogout() {
    localStorage.removeItem('federated_access');
    localStorage.removeItem('federated_refresh');
    localStorage.removeItem('federated_user_id');
    localStorage.removeItem('federated_cord');
    localStorage.removeItem('federated_profile');
    this.changeAuth(false);
  }

  @action getUserID() {
    this.userId = localStorage.getItem('federated_user_id');
    return this.userId;
  }

  // @action getCheckUserValidTaxAddress() {
  //   if (localStorage.getItem('federated_profile') !== null) {
  //     const profile = JSON.parse(localStorage.getItem('federated_profile'));

  //     if (profile.state.length === 0 && profile.zip.length === 0) {
  //       this.userValidForTax = false;
  //     } else {
  //       this.userValidForTax = true;
  //     }
  //   }
  // }

  @action locationFinder(data) {
    const getCord = JSON.parse(localStorage.getItem('federated_cord'));
    const geoData = { lat: getCord.lat, lng: getCord.lng };
    API.post('/catalog/locations/all/', geoData)
      .then((response) => {
        this.centralLocation = response.data.central_location;
        this.allCatalog = response.data.locations;

        if (data === 'fromHomeSession') {
          sessionStorage.setItem('federatedSession', false);
        }

        localStorage.setItem('federated_all_address', JSON.stringify(this.allCatalog));
        localStorage.setItem('federated_cords_central', JSON.stringify(this.centralLocation));

        this.getStoreAddressByLocationId(this.allCatalog[0].location_id);
      })
      .catch(() => {
        // console.log(error);
      });
  }

  @action getStoreAddressByLocationId(id) {
    let location_id = id;
    if (window.location.pathname === '/') {
      const homeDetails = window.location.search.slice(1).split('&');
      if (homeDetails.length === 1) {
        if (homeDetails[0] !== '') {
          location_id = (homeDetails[0].split('=')[1]).toString();
        }
      }
    }
    API.post('/catalog/locations/detail/', { location_id })
      .then((response) => {
        this.storeAddress = response.data;
        localStorage.setItem('federated_store_address', JSON.stringify(response.data));
      })
      .catch(() => {
        // console.log(error);
      });
  }

  @action getCartCount(count) {
    this.cartCount = count;
  }

 @ action saveGuestBillingInfo(info) {
    this.guestBillingInfo = info;
  }
}

export default AppStore;
